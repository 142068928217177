import React from "react";
import { Container } from "react-bootstrap";
import Page from "./Page";
import { useTranslation } from "react-i18next";

const PolicyPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <Container className="p-5">
        <h1 className="pb-3">{t("policy.title")}</h1>
        <li className="pb-3">{t("policy.free_delivery")}</li>
        <li className="pb-3">{t("policy.processing_time")}</li>
        <li className="pb-3">{t("policy.eu_delivery")}</li>
        <li className="pb-3">{t("policy.tracking_info")}</li>
        <li className="pb-3">{t("policy.no_po_boxes")}</li>
        <li className="pb-3">{t("policy.alternative_solutions")}</li>
        <li className="pb-3">{t("policy.damaged_notify")}</li>
      </Container>
    </Page>
  );
};

export default PolicyPage;
