import React from "react";
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaBusinessTime,
  FaInfoCircle,
} from "react-icons/fa";
import SocialIcons from "components/Shared/Icons/SocialIcons";
import { useTranslation } from "react-i18next";

const CONTACT_DETAILS = [
  {
    title: "contacts.info.email",
    icon: <FaEnvelope />,
    detail: "saidtappeti@gmail.com",
  },
  {
    title: "contacts.info.telephone",
    icon: <FaPhone />,
    detail: "070 754 5365",
  },
  {
    title: "contacts.info.address",
    icon: <FaMapMarkerAlt />,
    detail: "Viale Trieste 63/A, 09123 Cagliari",
  },
  {
    title: "contacts.info.timeTable",
    icon: <FaBusinessTime />,
    detail: "9:00-13:00, 16:00-20:00, Lunedì-sabato",
  },
  {
    title: "contacts.info.iva",
    icon: <FaInfoCircle />,
    detail: "Said Tappeti di Shamsali Saeid - P.IVA 02079510927",
  },
];

const ContactDetails: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center">
      {CONTACT_DETAILS.map(({ title, icon, detail }) => (
        <div className="my-4">
          <h5>
            {t(title)} {icon}
          </h5>
          {detail}
        </div>
      ))}
      <SocialIcons variant="light" />
    </div>
  );
};

export default ContactDetails;
